import React, { useRef, useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Snackbar,
  Grid,
  Typography,
  // FormGroup,
  // FormControlLabel,
  // Switch,
} from "@mui/material";
import ApiEndpoints from "../network/ApiEndPoints";
import {
  dateDifference,
  datemonthYear,
  dateToTime,
  ddmmyy,
  myDateDDMMTT,
} from "../utils/DateUtils";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { CustomStyles } from "../component/CustomStyle";
import BlockUnBlockModal from "../modals/BlockUnBlockModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { get } from "../network/ApiController";
import { apiErrorToast } from "../utils/ToastUtil";
import CachedIcon from "@mui/icons-material/Cached";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import MoneyTransferModal from "../modals/MoneyTransferModal";
import DmtModal from "../modals/DmtModal";
import AddRetailerinAdUser from "../modals/AddRetailerinAdUser";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { currencySetter } from "../utils/Currencyutil";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import ExcelUploadModal from "../modals/ExcelUploadModal";
import moment from "moment";
// import Loader from "../component/loading-screen/Loader";
import { primaryColor } from "../theme/setThemeColor";
import { useLocation } from "react-router-dom";
// ACTIONS SECTION
import UserServiceSetting from "../modals/UserServiceSetting";
import ViewUserModal from "../modals/ViewUserModal";
//
import AdminDocsViewModal from "../modals/AdminDocsViewModal";
import Mount from "../component/Mount";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import FilterCard from "../modals/FilterCard";
import {
  ROLE_LIST,
  ROLE_LIST4AD,
  adTab,
  adminTab,
  asmTab,
  mdTab,
  zsmTab,
} from "../utils/constants";
import FilterModal from "../modals/FilterModal";
import WalletDebitModal from "../modals/WalletDebitModal";
import AsmProductSaleModal from "../modals/admin/AsmProductSaleModal";
import BarChartIcon from "@mui/icons-material/BarChart";
import AdminDeletesUserModal from "../modals/AdminDeletesUserModal";
import RefreshComponent from "../component/RefreshComponent";
import AdminChargesForApiUsers from "../modals/AdminChargesForApiUsers";
import { styled, withStyles } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AdminCreateVirtualAcct from "../modals/AdminCreateVirtualAcct";
import CustomTabs from "../component/CustomTabs";
import { Icon } from "@iconify/react";
import AdminUserTab from "../component/Tab/AdminUserTab ";
import UserUpdateScheme from "../modals/UserUpdateScheme";
import AddUserSignUp from "../component/userSignUp/AddUserSignUp";
import StRateModel from "../modals/StRateModel";
import AdminCreateQr from "../modals/AdminCreateQr";
import DataRequestComponent from "./DataRequestComponent";
import RetDbTransactionTab from "../component/Tab/RetDbTransactionTab";

// styled tabls
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {/* {" "} */}
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  // borderRadius: "4px",
  padding: "2px 10px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 80,
    width: "0px",
    backgroundColor: "#ffffff",
  },
  minHeight: "30px",
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  color: "#fff",
  fontSize: "15px",
  minHeight: "15px",
  minWidth: "25px",
  padding: "4px 6px",
  borderRadius: "4px",
  textTransform: "none",
  // backgroundColor: getHoverInActive(),
  "&.Mui-selected": {
    color: "#fff",

    backgroundColor: primaryColor(),
    backgroundColor: `hsla(0,0%,100%,.2)`,
    transition: `background-color .3s .2s`,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

//  tab pannel ends

let handleCloseModal;
const AccTransactionsDataView = () => {
  const theme = useTheme();
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const [asmList, setAsmList] = useState([]);
  const [open, setOpen] = useState(false);
  const [adMdVal, setAdMdVal] = useState([]);
  // console.log("adMdVal", adMdVal);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [request, setRequest] = useState(false);
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [prefilledQuery, setPreFilledQuery] = useState(`platform=WEB`);
  const [tabQueryreset, setTabQueryreset] = useState(false);
  const location = useLocation();
  const [ip, setIp] = useState("");
  const [refreshTab, setRefreshTab] = useState(0);
    const [sumData, setSumData] = useState(false);
  let refresh;
  const hasFetched = useRef(false);
  // console.log("prefilled", prefilledQuery);
  const gotoAdminAgrement = (row) => {
    const queryString = new URLSearchParams({
      rowData: row?.id,
      name: row?.name,
      pan: row?.pan,
      role: row?.role,
      state: row?.state,
      establishment: row?.establishment,
      username: row?.username, // Directly use row.id here
    }).toString();

    console.log("Query String:", queryString);

    // Open the new tab with the generated query string
    window.open(`/admin_service?${queryString}&ip=${ip}&type=ret_dd`, "_blank");
  };
  const gotoAdminDistributorAgreement = (row) => {
    const queryString = new URLSearchParams({
      rowData: row?.id,
      name: row?.name,
      pan: row?.pan,
      role: row?.role,
      establishment: row?.establishment,
      username: row?.username, // Directly use row.id here
    }).toString();

    console.log("Query String:", queryString);

    // Open the new tab with the generated query string
    window.open(
      `/admin_distributoragreement?${queryString}&ip=${ip}&type=ret_dd`,
      "_blank"
    );
  };
  const getAdMdValue = (passValue) => {
    get(
      ApiEndpoints.GET_USERS,
      `page=1&paginate=10&role=${passValue ? "Md" : "Ad"}&platform=WEB&export=`,
      "",
      (res) => {
        const adArray = res?.data?.data;
        setAdMdVal(
          adArray &&
            adArray?.map((item) => {
              return {
                id: item.id,
                name: item.name,
                 establishment: item.establishment,
                username: item.username,
              };
            })
        );
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const [value, setValue] = useState(0);

  console.log("prefilledQuery", prefilledQuery);
 

  function refreshFunc(setQuery) {
    setQuery("");
    if (refresh) refresh();
  }

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log(err);
    }
  };

  const getAsmValue = () => {
    get(
      ApiEndpoints.GET_USERS,
      `page=1&paginate=10&role=Asm&platform=WEB&export=`,
      "",
      (res) => {
        const asmArray = res?.data?.data;
        setAsmList(
          asmArray &&
            asmArray?.map((item) => {
              return {
                id: item?.id,
                name: item?.name,
                username: item?.username,
              };
            })
        );
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const getExcel = () => {
    get(
      ApiEndpoints.ADMIN_ACC_GET_ACC_USER,
      `${
        query
          ? query + "&page=1&paginate=10&platform=WEB&export=1"
          : "page=1&paginate=10&platform=WEB&export=1"
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).utc().format("DD-MM-YYYY");
          const updated_at = moment(item.updated_at).utc().format("DD-MM-YYYY");
          return { ...item, created_at, updated_at };
        });
        json2Excel(
          `Users ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      ApiEndpoints.ADMIN_ACC_GET_ACC_USER,
      `${
        query
          ? query + "&page=1&paginate=10&platform=WEB&export=1"
          : "page=1&paginate=10&platform=WEB&export=1"
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Users ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  // useEffect(() => {
  //   if (asmList.length < 1) {
  //     getAsmValue();
  //   }
  //   if (adMdVal.length < 1) {
  //     getAdMdValue();
  //   }
  // }, []);
  // useEffect(() => {
  //   if (!hasFetched.current) {
  //     getAsmValue();
  //     getAdMdValue();
  //     hasFetched.current = true;
  //   }
  // }, []);
  useEffect(() => {
    if (location.state) {
      setQuery(`username=${location.state.username}`);
    }
  }, [location.state]);

  //

  const getParent = (row, parent = false, asm = false) => {
    if (parent) {
      let item =
        adMdVal && adMdVal.find((item) => item.id === parseInt(row.parent));
      // console.log("item", item);
      if (item) {
        return item.establishment;
      } else {
        return "";
      }
    } else if (asm) {
      let item = asmList && asmList.find((item) => item.id === Number(row.asm));
      // return item && item.name
      if (item) {
        return item.name;
      } else {
        return "";
      }
    }
  };
  // Helper function to format the date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    // Format the date part (Month, Day, Year)
    const datePart = date.toLocaleDateString("en-US", {
      month: "short", // "May"
      day: "numeric", // "12"
      year: "2-digit", // "24"
    });

    // Format the time part (Hour, Minute)
    const timePart = date.toLocaleTimeString("en-US", {
      hour: "2-digit", // "08"
      minute: "2-digit", // "30"
      hour12: true, // "AM/PM"
    });

    // Combine both parts
    return `${datePart} ${timePart}`;
  };

  


  const AccColumn = [
    {
      name: "Id",
      selector: (row) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "left",
            }}
          >
           <span>{row?.id}</span>
          </div>
        </>
      ),
      // width: "40px",
        width:"auto",
     
    },
    {
      name: "Date",

      selector: (row) => (
        <>
          <div
          style={{ textAlign: "left", cursor: "context-menu" }}
          >
            <Tooltip title={dateToTime(row.created_at)}>
              <span>{ddmmyy(row.created_at)}</span>
            </Tooltip>
          </div>
        
        </>
      ),
      // width: "90px", // Adjust width to accommodate date and time
        width:"auto"
    },

    {
      name: "Mobile",
      selector: (row) => (
        <>
          <div
            onClick={() => {
              copyToClipBoard(row.mobile);
              handleClickSnack();
            }}
            style={{ textAlign: "left" }}
          >
            {row.mobile}
          </div>
      

     
        </>
      ),
      wrap: true,
      // width:"110px"
        width:"auto"
    },
    {
      name: "Name",
      selector: (row) => (
        <>
          <div className="break-spaces">{row.name}</div>
          <div
            style={{
              whiteSpace: "break-spaces",
              overflow: "hidden",
              textOverflow: "clip",
              textAlign: "left",
              fontSize: "10px",
              color: primaryColor(),
            }}
          >
            {row.name}
          </div>
        </>
      ),
      wrap: true,
      // width:"200px"
        width:"auto"
    },
  
   
  
   
    {
      name: "Balance",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{currencySetter(row.balance / 100)}</div>
       
        </div>
      ),
      grow: 1,
      // width:"140px",
        width:"auto",
      center: false,
    },

    {
      name: "Type",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{row.type}</div>
       
        </div>
      ),
      grow: 1,
      // width:"140px",
        width:"auto",
      center: false,
    }, 
    {
      name: "ASM",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{row.asm}</div>
       
        </div>
      ),
      grow: 1,
      // width:"140px",
        width:"auto",
      center: false,
    },
       
    {
      name: "Cr Limit",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{row.creditlimit}</div>
       
        </div>
      ),
      grow: 1,
      // width:"140px",
        width:"auto",
      center: false,
    },
       
       


   
  ];

  const searchOptions = [
    { field: "EST", parameter: "establishment" },
    { field: "Mobile", parameter: "username" },
    {
      field: user?.role === "Ad" ? "" : "Outlet Id",
      parameter: user?.role === "Ad" ? "" : "instId",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* tab pannels here */}
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            // width: "100%",

            backgroundColor: "#e87204",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
        ></Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: { md: "none", sm: "none", xs: "flex" },
            justifyContent: "end",
            alignItems: "center",
            flexDirection: { md: "row" },
            pr: 1,
          }}
        >
          {/* excel */}
          <div className="mx-2">
            <ExcelUploadModal
              twobuttons="Download Csv"
              btn
              request={request}
              getExcel={getExcel}
              getCsv={getCsv}
              noOfResponses={noOfResponses}
              setQuery={setQuery}
              handleCloseCB={(closeModal) => {
                handleCloseModal = closeModal;
              }}
            />
          </div>
          {/* refresh */}
          <div className="">
            <RefreshComponent
              onClick={() => {
                refreshFunc(setQuery);
              }}
            />
          </div>
          {/* filter modal */}

          <FilterModal
            query={query}
            setQuery={setQuery}
            // ifRoleFilter
            ifestFilter
            ifUsernameFilter
            setTabQueryreset={setTabQueryreset}
            ifInstIdFilter={user?.role === "Admin"}
            // ifFingIdFilter={user?.role === "Admin"}
            // ifIrctcIdFilter={user?.role === "Admin"}
            ifAsmFilter={user?.role === "Admin" || user?.role === "Zsm"}
            roleList={user?.role === "Ad" ? ROLE_LIST4AD : ROLE_LIST}
            asmList={asmList}
            clearHookCb={(cb) => {
              refresh = cb;
            }}
            refresh={refresh}
          />
        </Grid>
      </Grid>
      <RetDbTransactionTab
            setQuery={setQuery}
            setRefreshTab={setRefreshTab}
            refreshTab={refreshTab}
          />
      <ApiPaginateSearch
        showSearch={false}
        apiEnd={ApiEndpoints.GET_TRANSACTIONS}
        searchOptions={searchOptions}
        setQuery={setQuery}
        columns={AccColumn}
        setSumData={setSumData}
        tabQueryreset={tabQueryreset}
        setTabQueryreset={setTabQueryreset}
        prefilledQuery={prefilledQuery}
        apiData={apiData}
        tableStyle={CustomStyles}
        setApiData={setApiData}
        queryParam={query ? query : ""}
        returnRefetch={(ref) => {
          refresh = ref;
        }}
        responses={(val) => {
          setNoOfResponses(val);
        }}
        isFilterAllowed={true}
        filterComponent={
          <FilterCard
            showSearch={false}
            query={query}
            setQuery={setQuery}
            // ifRoleFilter
            // ifAdIdFilter={user.role!=="Ad"}
            setTabQueryreset={setTabQueryreset}
            ifestFilter
            ifdateFilter
            // ifUsernameFilter
            setRefreshTab={setRefreshTab}
            tabQueryreset={tabQueryreset}
            ifInstIdFilter={user?.role === "Admin"}
            // ifFingIdFilter={user?.role === "Admin"}
            // ifIrctcIdFilter={user?.role === "Admin"}
            ifAsmFilter={user?.role === "Admin" || user?.role === "Zsm"}
            roleList={user?.role === "Ad" ? ROLE_LIST4AD : ROLE_LIST}
            asmList={asmList}
            clearHookCb={(cb) => {
              refresh = cb;
            }}
            refresh={refresh}
            // buttons
            actionButtons={
              <>
                <Box sx={{ display: "flex", ml: -2 }}>
                  {user && user.role !== "Ad" && (
                    <ExcelUploadModal
                      twobuttons="Download Csv"
                      btn
                      request={request}
                      getExcel={getExcel}
                      getCsv={getCsv}
                      noOfResponses={noOfResponses}
                      handleCloseCB={(closeModal) => {
                        handleCloseModal = closeModal;
                      }}
                    />
                  )}

                  <Tooltip title="refresh">
                    <IconButton
                      className=""
                      aria-label="refresh"
                      sx={{
                        color: "#0F52BA",
                      }}
                      onClick={() => {
                        refreshFunc(setQuery);
                      }}
                    >
                      <CachedIcon className="refresh-purple" />
                    </IconButton>
                  </Tooltip>
                  {
                    user && user.role === "Admin"
                      ? ""
                      : user && user.role === "Asm"
                      ? ""
                      : ""
                    // <AddRetailerinAdUser refresh={refresh} />
                  }
                  {user && user.role === "Admin" && (
                    <AddUserSignUp refresh={refresh} />
                  )}
                </Box>
              </>
            }
          />
        }
      />
    </Box>
  );
};

export default AccTransactionsDataView;

function AepsIdButton({ id, onClick }) {
  return (
    <Typography
      component="button"
      sx={{
        mt: 0.8,
        mx: 0.3,
        fontSize: "10px",
        fontWeight: "bold",
        color: primaryColor(),
      }}
      onClick={onClick}
    >
      {id}
    </Typography>
  );
}
