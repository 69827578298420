// import {
//   Box,
//   Button,
//   FormControl,
//   Grid,
//   InputAdornment,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useEffect } from "react";
// import ApiEndpoints from "../network/ApiEndPoints";
// import { useState } from "react";
// import EnterMpinModal from "../modals/EnterMpinModal";
// import { useContext } from "react";
// import AuthContext from "../store/AuthContext";
// import { cardNumberFormat } from "../utils/FormattingUtils";
// import { PATTERNS } from "../utils/ValidationUtil";
// import { rupayimg, visaimg, masterimg, back } from "../iconsImports";
// import CommonCardServices from "../View/CommonCardServices";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// // import * as Yup from "yup";
// // import { useForm } from "react-hook-form";
// // import { yupResolver } from "@hookform/resolvers/yup";

// const CreditcardForm = ({
//   type,
//   resetView,
//   title,
//   id,
//   dataCategories,
//   handleCardClick,
// }) => {
//   const authCtx = useContext(AuthContext);
//   const userLat = authCtx.location && authCtx.location.lat;
//   const userLong = authCtx.location && authCtx.location.long;
//   // const [request, setRequest] = useState(false);
//   const [modalVisible, setModalVisible] = useState(false);
//   // const [successRecharge, setSuccessRechage] = useState([]);
//   // const [showSuccess, setShowSuccess] = useState(false);
//   const [cardNumber, setCardNumber] = useState("");
//   const [showCardNumber, setShowCardNumber] = useState("");
//   const [data, setData] = useState("");
//   const [isMobv, setIsMobv] = useState(true);
//   const [cardType, setCardType] = useState("");
//   const [apiCallStatus, setApiCallStatus] = useState(false);

//   // const CreditCardSchema = Yup.object().shape({
//   //   amount: Yup.number().required().typeError("Incorrect format"),
//   //   interest_rate: Yup.number().required().typeError("Incorrect format"),
//   //   tenure: Yup.number().required().typeError("Incorrect format"),
//   // });

//   // const methods = useForm({
//   //   resolver: yupResolver(CreditCardSchema),
//   //   defaultValues: {
//   //     firstName: "",
//   //     lastName: "",
//   //   },
//   // });
//   const handleBack = () => {
//     resetView(false);
//   };

//   useEffect(() => {
//     if (apiCallStatus) {
//       document.getElementById("mobile").value = "";
//       document.getElementById("name").value = "";
//       document.getElementById("card_number").value = "";
//       document.getElementById("amount").value = "";
//       setCardType("");
//     }
//   }, [apiCallStatus]);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     const data = {
//       cnumber: cardNumber && cardNumber.split("-").join(""),
//       number: form.mobile.value,
//       name: form.name.value,
//       amount: form.amount.value,
//       latitude: userLat,
//       longitude: userLong,
//       pf: "WEB",
//     };
//     setData(data);
//     setModalVisible(true);
//   };

//   return (
//     <div>
//       <Box sx={{ p: 2 }}>
//       {dataCategories
//                   .filter((category) => category.title === "Utility")
//                   .map((category, index) => (
//                     <Box
//                       key={index}
//                       sx={{
//                         width: "100%",
//                         margin: "0 auto",
//                         marginBottom: 1,
//                         border: "1px solid lightgray",
//                         padding: { lg: 1.1, md: 1.2, xs: 2, sm: 3 },
//                         borderRadius: 3,

//                         paddingRight: 1,
//                         flexWrap: "wrap",
//                         boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
//                       }}
//                     >
//                       <Typography
//                         variant="h6"
//                         align="left"
//                         sx={{
//                           ml: 0.5,
//                           fontSize: { xs: "1rem", sm: "1.25rem" },
//                         }}
//                       >
//                         {category.title}
//                       </Typography>

//                       <Box
//                         sx={{
//                           overflowX: "scroll",
//                           height: "100px",
//                           display: "flex",
//                           flexDirection: "row",
//                           width: {
//                             lg: "67vw",
//                             md: "67vw",
//                             sm: "82vw",
//                             xs: "82vw",
//                           },
//                         }}
//                       >
//                         {category.data.map((item) => (
//                           <Box
//                             sx={{
//                               px: 1,
//                             }}
//                           >
//                             <CommonCardServices
//                               title={item.name}
//                               img={item.img}
//                               isSelected={id === item.id}
//                               onClick={() => handleCardClick(item)}
//                               width="200px"
//                             />
//                           </Box>
//                         ))}

                       
//                       </Box>
//                     </Box>
//                   ))}
//         <Grid
//           item
//           xs={12}
//           sm="auto"
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             mb: 2,
//           }}
//         >
//           <Button
//             size="small"
//             id="verify-btn"
//             onClick={handleBack}
//           >
//             {/* <span style={{ marginRight: "5px" }}>Home</span>
//             <img
//               src={back}
//               alt="back"
//               style={{ width: "18px", height: "20px" }}
//             /> */}
//                           <ArrowBackIcon sx={{ color: "#000", fontSize: "24px" }} />

//           </Button>
//         </Grid>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <Typography sx={{ fontSize: "24px", fontWeight: "bold" ,ml:3}}>
//             Credit Card Bill Payment
//           </Typography>
//         </div>
//         <Box
//           component="form"
//           id="creditCard"
//           validate
//           autoComplete="off"
//           onSubmit={handleSubmit}
//           sx={{
//             "& .MuiTextField-root": { m: 2 },
//             objectFit: "contain",
//             overflowY: "scroll",
//           }}
//         >
//           <Grid container sx={{ pt: 1 }} disabled>
//             <Grid item md={12} xs={12}>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   autoComplete="off"
//                   label="Registered Mobile Number"
//                   id="mobile"
//                   type="tel"
//                   size="small"
//                   error={!isMobv}
//                   inputProps={{ maxLength: 10 }}
//                   helperText={!isMobv ? "Enter valid Mobile" : ""}
//                   onChange={(e) => {
//                     setIsMobv(PATTERNS.MOBILE.test(e.target.value));
//                     if (e.target.value === "") setIsMobv(true);
//                   }}
//                   // onKeyDown={(e) => {
//                   //   console.log("e", e.which);
//                   //   if (e.key === "+" || e.key === "-") {
//                   //     e.preventDefault();
//                   //   }
//                   //   if (e.target.value.length === 10) {
//                   //     if (e.key.toLowerCase() !== "backspace")
//                   //       e.preventDefault();
//                   //     if (e.key.toLowerCase() === "backspace") {
//                   //     }
//                   //   }
//                   // }}
//                   required
//                 />
//               </FormControl>
//             </Grid>
//             <Grid item md={12} xs={12}>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   autoComplete="off"
//                   label="Name"
//                   id="name"
//                   size="small"
//                   onChange={(e) => {}}
//                   required
//                 />
//               </FormControl>
//             </Grid>
//             <Grid item md={12} xs={12}>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   autoComplete="off"
//                   label="Card Number"
//                   id="card_number"
//                   size="small"
//                   inputProps={{
//                     maxLength: 19,
//                   }}
//                   value={showCardNumber && showCardNumber}
//                   autoFocus={cardNumber && cardNumber}
//                   focused={cardNumber && cardNumber}
//                   onChange={(e) => {
//                     setShowCardNumber(cardNumberFormat(e.target.value));
//                     setCardNumber(e.target.value);
//                     if (e.target.value.startsWith("5")) {
//                       setCardType("master");
//                     } else if (e.target.value.startsWith("4")) {
//                       setCardType("visa");
//                     } else if (e.target.value.startsWith("6")) {
//                       setCardType("rupay");
//                     } else if (e.target.value === "") {
//                       setCardType("");
//                     } else {
//                       setCardType("");
//                     }
//                   }}
//                   required
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         {cardType && (
//                           <img
//                             src={`${
//                               cardType === "master"
//                                 ? masterimg
//                                 : cardType === "visa"
//                                 ? visaimg
//                                 : cardType === "rupay"
//                                 ? rupayimg
//                                 : ""
//                             }`}
//                             alt="card img"
//                             width="30"
//                           />
//                         )}
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </FormControl>
//             </Grid>

//             <Grid item md={12} xs={12}>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   autoComplete="off"
//                   label="Amount"
//                   id="amount"
//                   type="number"
//                   size="small"
//                   onChange={(e) => {
//                     document.getElementById("card_number").blur();
//                   }}
//                   required
//                   InputProps={{
//                     inputProps: {
//                       min: 1,
//                     },
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "+" || e.key === "-") {
//                       e.preventDefault();
//                     }
//                   }}
//                 />
//               </FormControl>
//             </Grid>
//           </Grid>
//         </Box>
//         <Button
//           type="submit"
//           form="creditCard"
//           className="btn-background"
//           sx={{
//             width: "95%",
//             mt: 1,
//           }}
//           disabled={!isMobv}
//           // endIcon={<ArrowForwardIosIcon />}
//           // disabled={request && request ? true : false}
//         >
//           {/* {params.length > 0 ? "Proceed to pay" : "Fetch Info"} */}
//           Proceed
//         </Button>

//         {modalVisible && modalVisible && (
//           <EnterMpinModal
//             data={data}
//             setData={setData}
//             setModalVisible={setModalVisible}
//             // setSuccessRechage={setSuccessRechage}
//             apiEnd={ApiEndpoints.BILLPAY_CC}
//             // setShowSuccess={setShowSuccess}
//             sendBkApiStatus={(val) => {
//               setApiCallStatus(val);
//             }}
//           />
//         )}
//       </Box>
//     </div>
//   );
// };

// export default CreditcardForm;
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import ApiEndpoints from "../network/ApiEndPoints";
import EnterMpinModal from "../modals/EnterMpinModal";
import AuthContext from "../store/AuthContext";
import { cardNumberFormat } from "../utils/FormattingUtils";
import { PATTERNS } from "../utils/ValidationUtil";
import { rupayimg, visaimg, masterimg, back } from "../iconsImports";
import CommonCardServices from "../View/CommonCardServices";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const CreditcardForm = ({
  type,
  resetView,
  title,
  id,
  dataCategories=false,
  handleCardClick,
}) => {
  const authCtx = useContext(AuthContext);
  const userLat = authCtx.location && authCtx.location.lat;
  const userLong = authCtx.location && authCtx.location.long;

  const [modalVisible, setModalVisible] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [showCardNumber, setShowCardNumber] = useState("");
  const [data, setData] = useState("");
  const [isMobv, setIsMobv] = useState(true);
  const [cardType, setCardType] = useState("");
  const [apiCallStatus, setApiCallStatus] = useState(false);

  const scrollContainerRef = useRef(null);

  // Handle scrolling through the cards
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleBack = () => {
    resetView(false);
  };

  useEffect(() => {
    if (apiCallStatus) {
      document.getElementById("mobile").value = "";
      document.getElementById("name").value = "";
      document.getElementById("card_number").value = "";
      document.getElementById("amount").value = "";
      setCardType("");
    }
  }, [apiCallStatus]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const data = {
      cnumber: cardNumber && cardNumber.split("-").join(""),
      number: form.mobile.value,
      name: form.name.value,
      amount: form.amount.value,
      latitude: userLat,
      longitude: userLong,
      pf: "WEB",
    };
    setData(data);
    setModalVisible(true);
  };

  return (
    <div>
      <Box sx={{ml:1.3  }}>
        
        {dataCategories?(dataCategories
          .filter((category) => category.title === "Utility")
          .map((category, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                margin: "0 auto",
                marginBottom: 1,
                border: "1px solid lightgray",
                padding: { lg: 1.1, md: 1.2, xs: 2, sm: 3 },
                borderRadius: 3,
                paddingRight: 1,
                flexWrap: "wrap",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h6"
                align="left"
                sx={{
                  ml: 0.5,
                  mt:"-8px",
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                {category.title}
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  // overflowX: "scroll",
                  height: "100px",
                  display: "flex",
                  mt:-2,
                  flexDirection: "row",
                  width: {
                    lg: "67vw",
                    md: "67vw",
                    sm: "82vw",
                    xs: "82vw",
                  },
                }}
              >
                {/* Scroll Left Button */}
                <IconButton
                  onClick={() => handleScroll("left")}
                  sx={{
                    position: "absolute",
                    left: 0,
                    mt:4,
                    zIndex: 1,
               color:"#000"
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>

                {/* Cards */}
                <Box
                  ref={scrollContainerRef}
                  sx={{
                    display: "flex",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    padding: 1,
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar
                    },
                  }}
                >
                  {category.data.map((item) => (
                    <Box key={item.id} sx={{ px: 1 }}>
                      <CommonCardServices
                        title={item.name}
                        img={item.img}
                        isSelected={id === item.id}
                        onClick={() => handleCardClick(item)}
                        width="200px"
                      />
                    </Box>
                  ))}
                </Box>

                {/* Scroll Right Button */}
                <IconButton
                  onClick={() => handleScroll("right")}
                  sx={{
                    position: "absolute",
                    right: -18,
                    mt:4,
                    zIndex: 1,
                   color:"#000"
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>
          ))):[]}
        <Grid
          item
          xs={12}
          sm="auto"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
         {dataCategories&&
            <Button
              size="small"
              onClick={handleBack}
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                p: 2,
                mr: 1,

                mb: 1,
              }}
            >
              <ArrowBackIcon sx={{ color: "#000", fontSize: "24px" }} />
            </Button>
}
        </Grid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold", ml: 3 }}>
            Credit Card Bill Payment
          </Typography>
        </div>
        <Box
          component="form"
          id="creditCard"
          validate
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { m: 2 },
            objectFit: "contain",
            overflowY: "scroll",
          }}
        >
          <Grid container sx={{ pt: 1 }} disabled>
             <Grid item md={12} xs={12}>
               <FormControl sx={{ width: "100%" }}>
             <TextField
                  autoComplete="off"
                  label="Registered Mobile Number"
                  id="mobile"
                  type="tel"
                  size="small"
                  error={!isMobv}
                  inputProps={{ maxLength: 10 }}
                  helperText={!isMobv ? "Enter valid Mobile" : ""}
                  onChange={(e) => {
                    setIsMobv(PATTERNS.MOBILE.test(e.target.value));
                    if (e.target.value === "") setIsMobv(true);
                  }}
                  // onKeyDown={(e) => {
                  //   console.log("e", e.which);
                  //   if (e.key === "+" || e.key === "-") {
                  //     e.preventDefault();
                  //   }
                  //   if (e.target.value.length === 10) {
                  //     if (e.key.toLowerCase() !== "backspace")
                  //       e.preventDefault();
                  //     if (e.key.toLowerCase() === "backspace") {
                  //     }
                  //   }
                  // }}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  autoComplete="off"
                  label="Name"
                  id="name"
                  size="small"
                  onChange={(e) => {}}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  autoComplete="off"
                  label="Card Number"
                  id="card_number"
                  size="small"
                  inputProps={{
                    maxLength: 19,
                  }}
                  value={showCardNumber && showCardNumber}
                  autoFocus={cardNumber && cardNumber}
                  focused={cardNumber && cardNumber}
                  onChange={(e) => {
                    setShowCardNumber(cardNumberFormat(e.target.value));
                    setCardNumber(e.target.value);
                    if (e.target.value.startsWith("5")) {
                      setCardType("master");
                    } else if (e.target.value.startsWith("4")) {
                      setCardType("visa");
                    } else if (e.target.value.startsWith("6")) {
                      setCardType("rupay");
                    } else if (e.target.value === "") {
                      setCardType("");
                    } else {
                      setCardType("");
                    }
                  }}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {cardType && (
                          <img
                            src={`${
                              cardType === "master"
                                ? masterimg
                                : cardType === "visa"
                                ? visaimg
                                : cardType === "rupay"
                                ? rupayimg
                                : ""
                            }`}
                            alt="card img"
                            width="30"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  autoComplete="off"
                  label="Amount"
                  id="amount"
                  type="number"
                  size="small"
                  onChange={(e) => {
                    document.getElementById("card_number").blur();
                  }}
                  required
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* Form Fields here */}
          {/* Example TextField Components */}
        </Box>
        <Button
          type="submit"
          form="creditCard"
          className="btn-background"
          sx={{
            width: "95%",
            mt: 1,
          }}
          disabled={!isMobv}
          // endIcon={<ArrowForwardIosIcon />}
          // disabled={request && request ? true : false}
        >
          {/* {params.length > 0 ? "Proceed to pay" : "Fetch Info"} */}
          Proceed
        </Button>

        {modalVisible && modalVisible && (
          <EnterMpinModal
            data={data}
            setData={setData}
            setModalVisible={setModalVisible}
            // setSuccessRechage={setSuccessRechage}
            apiEnd={ApiEndpoints.BILLPAY_CC}
            // setShowSuccess={setShowSuccess}
            sendBkApiStatus={(val) => {
              setApiCallStatus(val);
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default CreditcardForm;
