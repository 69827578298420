import { Box, Button, FormControl, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ApiPaginate from "../component/ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { CustomStyles } from "../component/CustomStyle";
import { useNavigate } from "react-router-dom";
import { currencySetter } from "../utils/Currencyutil";
// import { primaryColor, getHoverInActive } from "../theme/setThemeColor";
import MyButton from "../component/MyButton";
import BankDepDetailsModal from "../modals/admin/BankDepDetailsModal";
// import BarChartIcon from "@mui/icons-material/BarChart";
import { ddmmyy, dateToTime } from "../utils/DateUtils";
import { Icon } from "@iconify/react";
import BankChargesModal from "../modals/admin/BankChargesModal";
import UserAddBankModal from "../modals/UserAddBankModal";
import AuthContext from "../store/AuthContext";
import ActiveInactiveBank from "../modals/ActiveInactiveBank";

let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}
const AccBanksView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const [defaultStatus, setDefaultStatus] = useState("All");

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const theame = authCtx.theame;
  const user=authCtx.user
  const columns = [

    {
      name: "ID",
      selector: (row) => <div className="blue-highlight-txt">{row.id}</div>,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,

      width: "370px",
      wrap:"true",
    },
    {
      name: "Account",
      selector: (row) => row.accNo,
      
    },

    {
      name: "IFSC Code",
      selector: (row) => row.ifsc || "NA",
     
    },
    {
      name: "Branch Name",
      selector: (row) => row.branchname || "NA",
    },
    {
      name: "Balance",
      selector: (row) => currencySetter(row.balance),
    },

    {
      name: (
        <FormControl className="customized-textfield">
          <TextField autoComplete="off" select value={defaultStatus} sx={{ color: "#fff" }}>
            <MenuItem dense value="All">
              All
            </MenuItem>
            <MenuItem dense value="1">
              ACTIVE
            </MenuItem>
            <MenuItem dense value="0">
              IN-ACTIVE
            </MenuItem>
          </TextField>
        </FormControl>
      ),
      selector: (row) => (
        <ActiveInactiveBank row={row} refresh={refresh} />
      ),
      omit: user.id === 1  ? false : true,
    },
    {
      name: "Statement",
      selector: (row) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="statement">
            <MyButton
              green
              text="Statement"
              onClick={() => {
                navigate("/account/bankStatement", {
                  state: {
                    bank_id: row.id,
                    bank_name: row.name,
                    balance: row.balance,
                  },
                });
              }}
            />
          </Tooltip>
        </Box>
      ),
    },


  ];

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>


        <Tooltip title="refresh">
          <IconButton
            aria-label="refresh"
            sx={{
              color: "#0F52BA",
            }}
            onClick={() => {
              refreshFunc(setQuery);
            }}
          >
            <CachedIcon className="refresh-purple" />
          </IconButton>
        </Tooltip>
      </Box>
      <div>
        <ApiPaginate
          apiEnd={ApiEndpoints.GET_BANKS}
          columns={columns}
          apiData={apiData}
          tableStyle={CustomStyles}
          setApiData={setApiData}
          ExpandedComponent=""
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
          paginateServer={false}
          paginate={false}
        />
      </div>
    </Box>
  );
};

export default AccBanksView;
