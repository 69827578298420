import React, { useContext } from "react";
import useCommonContext from "../../store/CommonContext";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Grid, Tooltip, Link, Typography, Button } from "@mui/material";
import RefreshComponent from "../RefreshComponent";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../utils/FormattingUtils";
import { currencySetter } from "../../utils/Currencyutil";
import { datemonthYear } from "../../utils/DateUtils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { gradientColor, randomTheme } from "../../theme/setThemeColor";
import AuthContext from "../../store/AuthContext";
import { recent } from "../../iconsImports";
import Spinner from "../loading-screen/Spinner";

const RecentHistory = () => {
  const { getRecentData, recentData, recentLoading } = useCommonContext();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const theame = authCtx.theame;
  const user=authCtx.user
  console.log("authCtx ", authCtx);
  console.log("theme ", theame);
  return (
    <>
    {user.aggreement===1?
    <Box
      sx={{
        mt: 1.5,
        px: 1.5,
        py: 1.5,
        borderRadius: "10px",
        backgroundImage: `url(${recent})`,
        // backgroundImage: "linear-gradient(135deg, #4A90E2, #8E44AD)", // Gradient background
        // boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)", // Box shadow
        position: "relative", // Needed for layout
        // clipPath: `polygon(58% 0, 100% 0%, 100% 100%, 0 100%, 0 0, 42% 0, 50% 6%)`,
        // clipPath: "path(M 0 100 c 150 0 120 -75 200 -75 C 280 25 250 100 400 100 L400 1000 0 1000 Z)",
        backgroundSize: "cover", // Ensures the image covers the entire box
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
        backgroundPosition: "center",
         border :"1px solid  rgba(26, 91, 172, 0.2)"
      }}
    >
      <Spinner  loading={recentLoading} circleBlue />
      {/* Title */}
      <Box
        variant="h6"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "700",
          fontSize: "23px",
          position: "relative",
          color: "#111111", // Dark grey color
          mt:-1,
        }}
      >
        Recent History
        <Box
          sx={{
            position: "absolute",
            right: 0,
          }}
        >
          <RefreshComponent
            progressColor="#111"
            color="#111"
            refresh={recentLoading}
            onClick={() => {
              getRecentData();
            }}
            size="2rem"
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 1,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {recentData.map((data, index) => {
          const walletBal = Number(data.amount).toFixed(2);
          const statusColor =
            data.status === "SUCCESS"
              ? "#2C7F0B" // Solid green for border
              : data.status === "FAILED"
              ? "#FF668A" // Solid red for border
              : data.status === "REFUND"
              ? "#ff9800" // Solid orange for border
              : "#ffc107"; // Solid yellow for Pending

          const borderColor =
            data.status === "SUCCESS"
              ? "#0FB9F0" // Solid green for border
              : data.status === "FAILED"
              ? "#F94561" // Solid red for border
              : data.status === "REFUND"
              ? "#ff9800" // Solid orange for border
              : "#ffc107"; // Solid yellow for Pending
          const backgroundColor =
            data.status === "FAILED" ? "#FFF8F9" : "#F4F4F4";
          const bgcolor =
            // data.status === "SUCCESS" ? "#1A5BAC" : "#FF668A";
            data.status === "SUCCESS"
              ? "#1A5BAC" // Solid green for border
              : data.status === "FAILED"
              ? "#FF668A" // Solid red for border
              : data.status === "REFUND"
              ? "#ff9800" // Solid orange for border
              : "#ffc107"; // Solid yellow for Pending
          return (
            <Grid
              container
              key={index}
              wrap="nowrap"
              sx={{
                borderRadius: "6px 6px 0px 0px",
                borderTop: "1px solid #DADADA", // Top border
                borderLeft: "1px solid #DADADA", // Left border
                borderRight: "1px solid #DADADA",
                borderBottom: `3px solid ${borderColor}`,
                background: backgroundColor, // Dynamic background color

                // clipPath: "path("M 0 100 c 150 0 120 -75 200 -75 C 280 25 250 100 400 100 L400 1000 0 1000 Z")",
                px: 1,
                py: 1,
                mb: 1,

                // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={1.5}>
                <Box
                  sx={{
                    width: "30px", // Adjusted width to fit the icon size better
                    height: "30px", // Adjusted height for a better fit
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: bgcolor,
                  }}
                >
                  {data.status === "SUCCESS" && (
                    <ArrowUpwardIcon
                      sx={{
                        color: "#fff",
                        fontSize: "20px", // Adjust icon size
                        // backgroundColor: "#1A5BAC",
                        // borderRadius:"50%",
                      }}
                    />
                  )}
                  {data.status === "FAILED" && (
                    <ArrowDownwardIcon
                      sx={{
                        color: "#fff",
                        fontSize: "20px", // Adjust icon size
                        // backgroundColor: "#FF668A",
                        // borderRadius:"50%",
                      }}
                    />
                  )}
                  {data.status === "REFUND" && (
                    <SyncIcon
                      sx={{
                        color: "#fff",
                        fontSize: "20px", // Adjust icon size
                        // backgroundColor: "#ff9800",
                        // borderRadius:"50%",
                      }}
                    />
                  )}
                  {data.status === "PENDING" && (
                    <PriorityHighIcon
                      sx={{
                        color: "#fff",
                        fontSize: "20px", // Adjust icon size
                        // backgroundColor: "#ffc107",
                        // borderRadius:"50%",
                      }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start", // Align content to the top
                  alignItems: "flex-start", // Align content to the left
                  color: "#004080",
                  ml: 0.4, // Small left margin
                  gap: "3px", // Add some spacing between elements
                  width: "140px",
                }}
              >
                <Tooltip title={data.operator}>
                  <Box
                    sx={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      textAlign: "left",
                    }}
                  >
                    {data.operator}
                  </Box>
                </Tooltip>
                <Box
                  sx={{ fontSize: "12px", fontWeight: "500", color: "#1A5BAC" }}
                >
                  {data.number}
                </Box>
                <Box
                  sx={{ fontSize: "12px", fontWeight: "500", color: "#1A5BAC" }}
                >
                  {datemonthYear(data.created_at)}
                </Box>
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Box
                  sx={{
                    color: "#1A5BAC",
                    // data.status === "SUCCESS"
                    //   ? "#2C7F0B"
                    //   : data.status === "PENDING"
                    //   ? "#ffc107"
                    //   : data.status === "REFUND"
                    //   ? "#ff9800"
                    //   : "#FF668A",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  <span>{data.txn_type === "DR" ? "- " : "+ "}</span>
                  {currencySetter(walletBal)}
                </Box>
                <Box
                  sx={{
                    color:
                      data.status === "SUCCESS"
                        ? "#2C7F0B"
                        : data.status === "PENDING"
                        ? "#ffc107"
                        : data.status === "REFUND"
                        ? "#ff9800"
                        : "#FF668A",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {capitalize(data.status)}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>

      <Box sx={{ textAlign: "right", mt: 0.8, ml: 3 }}>
        <Button
          to="/customer/transactions"
          onClick={() => navigate("transactions")}
          style={{
            textDecoration: "none",
            fontWeight: "500",
            fontSize: "12px",
            color: "#fff", // White text for contrast
            background: "#1877F2",
            padding: "0.5rem ", // Spacious padding
            borderRadius: "4px", // Pill-shaped button
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Deeper shadow for depth
            transition: "all 0.3s ease-in-out", // Smooth animations
            textTransform: "uppercase", // Stylish uppercase text
          }}
        >
          View More
        </Button>
      </Box>
    </Box>
:null}
    </>
  );
};

export default RecentHistory;
