import React, { useContext } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import AuthContext from "../store/AuthContext";
import { randomTheme } from "../theme/setThemeColor";

const CommonCardDashBoard = ({ name, img, onClick }) => {
  const authCtx = useContext(AuthContext);
  const theame = authCtx.theame;

  return (
    <Tooltip title={name} placement="top">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff", // Clean white background
          border: "1px solid #dcdcdc", // Subtle gray border
          borderRadius: "12px", // Rounded corners for a polished look
          padding: { xs: "16px", sm: "8px" },
          textAlign: "center",
          cursor: "pointer",
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)", // Light shadow for a professional finish

          "&:hover": {
            transform: "translateY(-2px)", // Subtle lift on hover
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)", // Slightly enhanced shadow on hover
          },
        
        }}
        onClick={onClick}
      >
        {/* Image Container */}
        <Box
          sx={{
            width: { xs: "80px", sm: "70px" },
            height: { xs: "80px", sm: "70px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f9f9f9", // Neutral background
            borderRadius: "50%", // Circular image container
            overflow: "hidden",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
          }}
        >
          <img
            src={img}
            alt={name}
            style={{
              width: "55%",
              height: "55%",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Text */}
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            color: "#333", // Neutral dark text color
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            fontSize: { xs: "14px", sm: "15px" },
            marginTop: "8px",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CommonCardDashBoard;
{
  /* <Tooltip title={name} placement="top">
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(145deg, #ffffff, #e6e6e6)", // 3D gradient effect
    border: "1px solid #dcdcdc", // Subtle gray border
    borderRadius: "16px", // Rounded corners for a polished 3D look
    padding: { xs: "18px", sm: "24px" },
    textAlign: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    boxShadow:
      "8px 8px 16px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.8)", // 3D shadow effect

    "&:hover": {
      transform: "translateY(-4px)", // Lift effect for 3D feel
      boxShadow:
        "12px 12px 24px rgba(0, 0, 0, 0.15), -6px -6px 12px rgba(255, 255, 255, 0.9)", // Enhanced shadow on hover
    },
  }}
  onClick={onClick}
>
  {/* Image Container */
}
// <Box
//   sx={{
//     width: { xs: "90px", sm: "100px" },
//     height: { xs: "90px", sm: "100px" },
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     background: "linear-gradient(145deg, #f0f0f0, #dcdcdc)", // 3D background
//     borderRadius: "50%", // Circular image container
//     overflow: "hidden",
//     boxShadow:
//       "inset 4px 4px 8px rgba(0, 0, 0, 0.05), inset -4px -4px 8px rgba(255, 255, 255, 0.8)", // Inner 3D shadow
//   }}
// >
//   <img
//     src={img}
//     alt={name}
//     style={{
//       width: "65%",
//       height: "65%",
//       borderRadius: "50%",
//       objectFit: "cover",
//     }}
//   />
// </Box>

{
  /* Text */
}
// <Typography
//   variant="body1"
//   sx={{
//     fontWeight: 500,
//     color: "#333", // Neutral dark text color
//     textAlign: "center",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//     maxWidth: "100%",
//     fontSize: { xs: "15px", sm: "16px" },
//     marginTop: "10px",
//     textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Subtle text shadow for depth
//   }}
// >
//   {name}
// </Typography>
// </Box>
// </Tooltip>
