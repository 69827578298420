import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import ApiPaginate from "../component/ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { massegetable } from "../component/CustomStyle";
import { ddmmyy, dateToTime1 } from "../utils/DateUtils";
import { Icon } from "@iconify/react";
import BankChargesModal from "../modals/admin/BankChargesModal";
import UserAddBankModal from "../modals/UserAddBankModal";
import AuthContext from "../store/AuthContext";
import FilterCard from "../modals/FilterCard";
import ApiPaginateSearch from "./ApiPaginateSearch";
import useCommonContext from "../store/CommonContext";
import { currencySetter } from "../utils/Currencyutil";
// import { useNavigate } from "react-router-dom";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { get, postJsonData } from "../network/ApiController";
import AdjustEntriesModal from "../modals/AdjustEntriesModal";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import moment from "moment";
import ExcelUploadModal from "../modals/ExcelUploadModal";
let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}
const UnclaimedEntries = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const [rowStatus, setRowStatus] = useState({});
  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const {
    setChooseInitialCategoryFilter,
    chooseInitialCategoryFilter,
    refreshUser,
  } = useCommonContext();
  const inputComponent = useRef();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  // const [defaultTxn, setdefaultTxn] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const bal = location?.state?.bal;
  const mobile = location?.state?.mobile;
  const [currentAsm, setCurrentAsm] = useState([]);
  const [defaultTxn, setDefaultTxn] = useState({});
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  const [bank, setBank] = useState("");
  const [desc, setDesc] = useState("");
  const [bankid, setBankid] = useState("");
  const [balance, setBalance] = useState("");
  const [request, setRequest] = useState(false);
  const [excelrequest, setExcelRequest] = useState(false);
  const [defaultRemark, setDefaultRemark] = useState("");
  const [pendingTxnList, setPendingTxnList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [resetData, setResetData] = useState("");
  const [noOfResponses, setNoOfResponses] = useState(0);
  const handleChange = ({ selectedRows }) => {
    setResetData("");
    setSelectedRows(selectedRows);
  };
  console.log("selected rows------", selectedRows);

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allRowIds = apiData.map((row) => row.id);
      setSelectedRows(allRowIds);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  const getAccounts = () => {
    get(
      ApiEndpoints.GET_ACCOUNTS,
      "",
      "",
      (res) => {
        if (res && res.data) {
          setAccounts(res.data.data);
        }
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    getAccounts();
  };
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setAccounts("");

    setResetData("reset");
    if (refresh) refresh();
    setToggleClearRows(!toggledClearRows);
    // Clear accounts
    setDefaultTxn({}); // Reset default transaction
    // setResetData("reset") // Clear row status
    // setToggleClearRows((prev) => !prev); // Toggle to clear selected rows in table
    if (selectedRows?.length > 0) setSelectedRows([]); // Deselect all rows
  };

  let refresh;

  function refreshFunc(setQueryParams) {
    setQueryParams("");
    setResetData("reset");
    if (refresh) refresh();
    setToggleClearRows(!toggledClearRows);
  }
  const columns = [
    {
      name: "ID",
      selector: (row) => <div className="blue-highlight-txt">{row.id}</div>,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => (
        <div>
          {ddmmyy(row.created_at)} {dateToTime1(row.created_at)}
        </div>
      ),
      width: "140px",
    },
    {
      name: "Bank",
      selector: (row) => row.bank,
      width: "170px",
      fontSize: "13px",
    },
    {
      name: "Description",
      selector: (row) => (
        <div
          style={{
            fontSize: "13px",
          }}
        >
          {row.description}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Credit",
      selector: (row) => {
        return (
          // row.credit,
          <div style={{ color: "green", textAlign: "left" }}>
            +{currencySetter(row.credit)}
          </div>
        );
      },
      width: "100px",
      fontSize: "13px",
    },
    {
      name: "Debit",
      selector: (row) => {
        return (
          // row.debit
          <div style={{ color: "red", textAlign: "left" }}>
            -{currencySetter(row.debit)}
          </div>
        );
      },
      width: "100px",
      fontSize: "13px",
    },
  ];
  const getExcel = () => {
    get(
      ApiEndpoints.GET_PENDING_ACCOUNT_TRANSACTION,
      "",
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `Accounts ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleModalClose();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      ApiEndpoints.GET_PENDING_ACCOUNT_TRANSACTION,
      "",
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleModalClose();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };
  console.log("the default accoun is s", defaultTxn);
  return (
    <Box>
      <ApiPaginateSearch
        showSearch={false}
        apiEnd={ApiEndpoints.GET_PENDING_ACCOUNT_TRANSACTION}
        setQuery={setQuery}
        query={query}
        columns={columns}
        selectableRows
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        isFilterAllowed={true}
        apiData={apiData}
        reset={resetData}
        tableStyle={massegetable}
        setApiData={setApiData}
        ExpandedComponent=""
        responses={(val) => {
          setNoOfResponses(val);
        }}
        queryParam={query ? query : ""}
        returnRefetch={(ref) => {
          refresh = ref;
        }}
        filterComponent={
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <FilterCard
                  ifdateFilter
                  ifFromBankFilter
                  ifamountFilter
                  ifdescriptionFilter
                  setQuery={setQuery}
                  query={query}
                  chooseInitialCategoryFilter={
                    chooseInitialCategoryFilter !== "ALL"
                      ? chooseInitialCategoryFilter
                      : false
                  }
                  refresh={refresh}
                  isShowFilterCard={isShowFilterCard}
                  setIsShowFilterCard={setIsShowFilterCard}
                  actionButtons={
                    <Box sx={{ display: "flex", ml: -2 }}>
                      <Tooltip title="refresh">
                        <IconButton
                          aria-label="refresh"
                          sx={{
                            color: "#0F52BA",
                          }}
                          onClick={() => {
                            refreshFunc(setQuery);
                          }}
                        >
                          <CachedIcon className="refresh-purple " />
                        </IconButton>
                      </Tooltip>
                      <ExcelUploadModal
                  twobuttons="Download Csv"
                  btn
                  request={request}
                  getExcel={getExcel}
                  getCsv={getCsv}
                  noOfResponses={noOfResponses}
                  setQuery={setQuery}
                  // handleCloseCB={(closeModal) => {
                  //   handleCloseModal = closeModal;
                  // }}
                />
                    </Box>
                  }
                />
            
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleModalOpen}
                disabled={selectedRows.length === 0}
              >
                Adjust Account
              </Button>

              <AdjustEntriesModal
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
                selectedRows={selectedRows}
                defaultTxn={defaultTxn}
                setDefaultTxn={setDefaultTxn}
                defaultRemark={defaultRemark}
                setDefaultRemark={setDefaultRemark}
                accounts={accounts}
                rowStatus={rowStatus}
                refresh={refresh}
                setRowStatus={setRowStatus}
              />
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default UnclaimedEntries;

// UnclaimedEntries
