import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import RetProductionSaleComponent from "../component/RetProductionSaleComponent";
import AskForDocsModal from "../modals/AskForDocsModal";
import AuthContext from "../store/AuthContext";
import Mount from "../component/Mount";
import { USER_ROLES } from "../utils/constants";
import MyEarnings from "../component/dashboard/retailer/MyEarnings";
import NewsSection from "../component/NewsSection";
import { Box, Grid } from "@mui/material";
import useCommonContext from "../store/CommonContext";

const RetDdDashboard = () => {
  const [graphDuration, setGraphDuration] = useState("TODAY");
  const [graphRequest, setGraphRequest] = useState(false);
  const [open, setopen] = useState(false);
  const { getRecentData, refreshUser, userRequest } = useCommonContext();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const role = authCtx.user.role;
  const user = authCtx.user;
  useEffect(() => {
    if (
      authCtx.ifDocsUploaded &&
      (authCtx.ifDocsUploaded.pan_image === 0 ||
        authCtx.ifDocsUploaded.aadhaar_image === 0) &&
      location?.state?.login
    ) {
      setTimeout(() => {
        setopen(true);
      }, 2000);
    }
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ml:1.4}}>
  {user?.aggreement === 1 &&  <NewsSection/>}
      {user?.aggreement === 1 ? (
        <Grid container sx={{ gap: 2, }}>
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              border: "1px solid #ccc",
              borderRadius: 2,
            }}
          >
            <RetProductionSaleComponent
              graphDuration={graphDuration}
              setGraphDuration={setGraphDuration}
              graphRequest={graphRequest}
              setGraphRequest={setGraphRequest}
              role={role}
              USER_ROLES={USER_ROLES}
            />

            <AskForDocsModal open={open} setopen={setopen} />
          </Grid>
        </Grid>
      ) : (
        <Box>
          {/* <Grid
            item
            className="card-css"
            sx={{ p: 3, mt: 2, mb: { md: 0, xs: 5 } }}
          >
            <h5>To use our services, please sign the agreement</h5>
            <br />
            {user.role === "Ret" || user.role === "Dd" ? (
              <a href="/service" target="_blank" rel="noopener noreferrer">
                Click here
              </a>
            ) : (
              <a
                href="/distributoragreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>
            )}
          </Grid> */}
          <Grid
            item
            className="card-css"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              p: 4,

              position: "absolute",
              top: "50%",
              left: "40%",
              transform: "translate(-50%, -50%)",

              backgroundColor: " rgb(73, 132, 200) ",
              borderRadius: "10px",
              boxShadow: 3,
              maxWidth: "600px",

              height: "300px",
            }}
          >
            <Box sx={{ textAlign: "center", color: "#fff", width: "100%" }}>
              <h5
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginBottom: "20px",
                  letterSpacing: "1px",
                }}
              >
                To use our services, please sign the agreement
              </h5>

              <br />
              {user.role === "Ret" || user.role === "Dd" ? (
                <a
                  href="/service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff", // White color for the link
                    fontSize: "18px", // Slightly larger font for the link
                    fontWeight: "500", // Medium weight for the link
                    textDecoration: "none", // No underline by default
                    backgroundColor: "rgb(220, 42, 113)", // Dark blue background for a button effect
                    padding: "8px 20px", // Padding inside the button
                    borderRadius: "30px", // Rounded button shape
                    display: "inline-block",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "rgb(26, 32, 38)"; // Darken blue on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "rgb(38, 50, 56)"; // Revert to original color
                  }}
                >
                  Click here
                </a>
              ) : (
                <a
                  href="/distributoragreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff", // White color for the link
                    fontSize: "18px", // Slightly larger font for the link
                    fontWeight: "500", // Medium weight for the link
                    textDecoration: "none", // No underline by default
                    backgroundColor: "rgb(148, 135, 22)", // Light yellow background for a button effect
                    padding: "8px 20px", // Padding inside the button
                    borderRadius: "30px", // Rounded button shape
                    display: "inline-block", // Inline-block to maintain button structure
                    transition: "background-color 0.3s", // Smooth background color change
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "rgb(255, 193, 7)"; // Darken yellow on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "rgb(255, 235, 59)"; // Revert to original color
                  }}
                >
                  Click here
                </a>
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RetDdDashboard;
