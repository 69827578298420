import React from "react";
import { useState } from "react";
import { deleteJsonData } from "../../network/ApiController";
import ApiEndpoints from "../../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../../utils/ToastUtil";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Loader from "../loading-screen/Loader"; 
import ModalHeader from "../../modals/ModalHeader";
import ModalFooter from "../../modals/ModalFooter";
import PersonIcon from "@mui/icons-material/Person";
import { secondaryColor } from "../../theme/setThemeColor";
import { Info } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  height: "max-content",
  overflowY: "scroll",
  p: 2,
};
const DeleteEmployees = ({ row, refresh }) => {
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteEmployee = () => {
    deleteJsonData(
      ApiEndpoints.EMPLOYEES,
      { id: row.id },
      "",
      setRequest,
      (res) => {
        okSuccessToast(res?.data?.message);
        if (refresh) refresh();
        handleClose();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  return (
    <div>
      <Tooltip title="Delete Account">
        <IconButton onClick={handleClickOpen}>
          <Icon
            icon="ic:outline-delete"
            style={{ color: "red", fontSize: "24px" }}
          />
        </IconButton>
      </Tooltip>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#e57373" }}>
          Delete Employee
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ minWidth: "400px" }}>
              <section>
                <span>Are you sure you want to delete this Employee!</span>
              </section>
              <section style={{ marginTop: "18px" }}>
                <div className="my-2">
                  <span style={{ marginRight: "8px", color: "#000" }}>
                    Name:
                  </span>{" "}
                  <span
                    style={{ fontWeight: "600", color: "#000", opacity: "0.7" }}
                  >
                    {row.name ?? "NA"}
                  </span>
                </div>
                <div className="my-2">
                  <span style={{ marginRight: "8px", color: "#000" }}>
                    Bank:
                  </span>{" "}
                  <span
                    style={{ fontWeight: "600", color: "#000", opacity: "0.7" }}
                  >
                    {row.bank ?? "NA"}
                  </span>
                </div>
                <div className="my-2">
                  <span style={{ marginRight: "8px", color: "#000" }}>
                    Account:
                  </span>{" "}
                  <span
                    style={{ fontWeight: "600", color: "#000", opacity: "0.7" }}
                  >
                    {row.acc_number ?? "NA"}
                  </span>
                </div>
                <div>
                  <span style={{ marginRight: "8px", color: "#000" }}>
                    IFSC:
                  </span>{" "}
                  <span
                    style={{ fontWeight: "600", color: "#000", opacity: "0.7" }}
                  >
                    {row.ifsc ?? "NA"}
                  </span>
                </div>
              </section>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            sx={{ backgroundColor: "#ef5350", p: 0.6, fontSize: "12px" }}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            sx={{ backgroundColor: "#4caf50", p: 0.6, fontSize: "12px" }}
            onClick={() => {
              deleteEmployee();
            }}
            disabled={request}
          >
            <Loader loading={request} size="small" />
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <Loader loading={request} />
          <ModalHeader
            title="Delete Employee" 
            handleClose={handleClose}
          />
          <Typography
            className="text-center"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: secondaryColor(),
            }}
          >
            <Info  sx={{ mr: 1 }} />
            {`Are you sure you want to delete this Employee!`}
          </Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            className="text-center"
            sx={{
              "& .MuiTextField-root": { m: 2 },
            }}
          >
            <Grid container sx={{ pt: 1 }}>
              <Grid 
                item
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <table className="mt-table">
                  <tr>
                    <td style={{ padding: "16px", paddingBottom: "6px" }}>
                      <PersonIcon sx={{ mr: 1, fontSize: "16px" }} />
                      Name:
                    </td>
                    <td style={{ padding: "16px", paddingBottom: "6px" }}>:</td>
                    <td style={{ padding: "16px", paddingBottom: "6px" }}>
                    {row.name ?? "NA"}

                      {/* {"(" + bene.bank + ")"} */}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                      <AccountBalanceIcon
                        fontSize="small"
                        sx={{ mr: 1, fontSize: "16px" }}
                      />
                      Bank{" "}
                    </td>{" "}
                    <td style={{ padding: "16px", paddingTop: "6px" }}>:</td>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                    {row.bank ?? "NA"}

                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                      <AccountBalanceIcon
                        fontSize="small"
                        sx={{ mr: 1, fontSize: "16px" }}
                      />
                      Account{" "}
                    </td>{" "}
                    <td style={{ padding: "16px", paddingTop: "6px" }}>:</td>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                    {row.acc_number ?? "NA"}

                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                      <AccountBalanceIcon
                        fontSize="small"
                        sx={{ mr: 1, fontSize: "16px" }}
                      />
                      Ifsc{" "}
                    </td>{" "}
                    <td style={{ padding: "16px", paddingTop: "6px" }}>:</td>
                    <td style={{ padding: "16px", paddingTop: "6px" }}>
                    {row.ifsc ?? "NA"}

                    </td>
                  </tr>
                </table>
              </Grid>

              {/* {isConfirmed && (
                <Grid
                  item
                  md={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControl sx={{ width: "76%" }}>
                    <TextField
                      autoComplete="off"
                      label="Enter OTP"
                      id="otp"
                      size="small"
                      required
                      // sx={{
                      //   "& label": {
                      //     marginLeft: "35%",
                      //   },
                      //   "&.Mui-focused": {
                      //     marginLeft: "35%",
                      //   },
                      // }}
                    />
                  </FormControl>
                </Grid>
              )} */}
            </Grid>
              <Button
                sx={{ width: "70%", mt: 2 }}
                className="btn-background"
                disabled={request}
                onClick={() => {
                  deleteEmployee();
                }}
              >
                Delete
              </Button>
          </Box>
         
          <ModalFooter
              handleClose={handleClose}
              btn="Cancel"
            />
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteEmployees;
