/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Modal,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../loading-screen/Loader";
import ModalHeader from "../../modals/ModalHeader";
import ModalFooter from "../../modals/ModalFooter";
import { whiteColor } from "../../theme/setThemeColor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { postJsonData } from "../../network/ApiController";
import ApiEndpoints from "../../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../../utils/ToastUtil";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  p: 2,
  maxHeight: "80vh",
  overflowY: "auto",
};

const CreateEmployees = ({ refresh, edit = false, row = {} }) => {
  const [open, setOpen] = React.useState(false);
    const [request, setRequest] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: row?.name || "",
    joining_date: row?.joining_date ? dayjs(row.joining_date) : null,
    role: row?.role || "",
    dob: row?.dob ? dayjs(row.dob) : null,
    basic_pay: row?.basic_pay || "",
    hra: row?.hra || "",
    ta: row?.ta || "",
    target: row?.target || "",
    bank: row?.bank || "",
    ifsc: row?.ifsc || "",
    acc_number: row?.acc_number || "",
    parent: row?.parent || "",
    pan: row?.pan || "",
    aadhaar: row?.aadhaar || "",
    address: row?.address || "",
    mobile: row?.mobile || "",
    alternate: row?.alternate || "",
    emergency: row?.emergency || "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, newValue) => {
    setFormData({ ...formData, [name]: newValue });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.name || !formData.role || !formData.mobile) {
      apiErrorToast("Please fill all required fields.");
      return;
    }

    const payload = {
      ...formData,
      joining_date: formData.joining_date ? formData.joining_date.format("YYYY-MM-DD") : null,
      dob: formData.dob ? formData.dob.format("YYYY-MM-DD") : null,
    };
const endpoint=edit?ApiEndpoints.EDIT_EMPLOYEES: ApiEndpoints.ADD_EMPLOYEES
    postJsonData(
      endpoint,
     { ...payload},
     setRequest,
      (res) => {
        okSuccessToast(edit ? "Employee updated successfully" : "Employee created successfully");
        setOpen(false);
        refresh && refresh(); // Refresh data if provided
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      {edit ? (
        <Tooltip title="Edit Employee">
          <IconButton onClick={handleOpen}>
            <EditIcon style={{ color: "blue" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add Employee">
          <Button
            className="navHover"
            variant="outlined"
            sx={{
              py: 0.5,
              px: 1,
              backgroundColor: "#0077c0",
              textTransform: "capitalize",
              borderRadius: "2px",
              color: "#fff",
            }}
            onClick={handleOpen}
            startIcon={
              <IconButton sx={{ p: 0, color: whiteColor() }}>
                <AddCircleOutlineIcon />
              </IconButton>
            }
          >
            Employee
          </Button>
        </Tooltip>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="sm_modal">
          <Loader loading={false} />
          <ModalHeader title={edit ? "Edit Employee" : "Create Employee"} handleClose={handleClose} />
          <Box component="form" id="employees" autoComplete="off" onSubmit={handleSubmit} sx={{ "& .MuiTextField-root": {  } }}>
            <Grid container spacing={2}>
              {[
                { label: "Name", name: "name" },
                { label: "Role", name: "role" },
                { label: "Basic Pay", name: "basic_pay", type: "number" },
                { label: "Bank", name: "bank" },
                { label: "IFSC", name: "ifsc" },
                { label: "Account Number", name: "acc_number" },
                { label: "Parent", name: "parent" },
                { label: "PAN", name: "pan" },
                { label: "Aadhaar", name: "aadhaar" },
                { label: "Address", name: "address" },
                { label: "Mobile", name: "mobile" },
                { label: "Alternate Mobile", name: "alternate" },
                { label: "Emergency Contact", name: "emergency" },
              ].map((field, index) => (
                <Grid item md={6} xs={12} key={index}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      size="small"
                      required
                      type={field.type || "text"}
                      value={formData[field.name]}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              ))}
              {["joining_date", "dob"].map((dateField, index) => (
                <Grid item md={6} xs={12} key={index}>
                  <FormControl sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={dateField === "joining_date" ? "Joining Date" : "Date of Birth"}
                        value={formData[dateField]}
                        onChange={(newValue) => handleDateChange(dateField, newValue)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Box>
          <ModalFooter form="employees" type="submit" btn="Submit" />
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateEmployees;
