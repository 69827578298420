import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import AuthContext from "../store/AuthContext";
import { get, postFormData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { numberSetter } from "../utils/Currencyutil";
import useCommonContext from "../store/CommonContext";
import { validateApiCall } from "../utils/LastApiCallChecker";
import RefreshComponent from "./RefreshComponent";
import { useLocation } from "react-router-dom";
import { keyframes } from "@mui/system";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import {
  getFirmContact,
  getFirmEmail,
  gradientColor,
} from "../theme/setThemeColor";
import { Email, Phone } from "@mui/icons-material";
// import ThemeSelector from "../modals/ThemeSelector";

const WalletCard = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const parentResponse = authCtx.parentResponse;
  const { apiBal } = authCtx;
  const userLat = authCtx.location && authCtx.location.lat;
  const userLong = authCtx.location && authCtx.location.long;
  const [showQr, setShowQr] = useState(false);
  const [open, setOpen] = useState(false);
  const [showWalletTransfer, setShowWalletTransfer] = useState(false);
  const [showBankTransfer, setShowBankTransfer] = useState(false);
  const instId = user && user.instId;
  const [walletTransferErrMsg, setWalletTransferErrMsg] = useState("");
  const [request, setRequest] = useState(false);
  const { getRecentData, refreshUser, userRequest } = useCommonContext();
  const [err, setErr] = useState();
  // const [response, setResponse] = useState(parentResponse);
  const location = useLocation();
  console.log("parentResponse", parentResponse);

  const selfqrValue =
    instId && instId
      ? `upi://pay?pa=ipay.133876.` +
        instId +
        "@icici" +
        `&pn=${user && user.establishment}` +
        "&cu=INR"
      : "";

  // ######################################
  // W2 TO W1 TRANSFER API CALL ...........
  // ######################################
  const handleW2ToW1Transfer = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = {
      amount: form.w2_amount.value,
      pf: "WEB",
      latitude: userLat,
      longitude: userLong,
    };

    if (validateApiCall()) {
      postFormData(
        ApiEndpoints.W2TOW1_TRANSFER,
        data,
        setRequest,
        (res) => {
          okSuccessToast(res.data.message);
          setWalletTransferErrMsg("");
          document.getElementById("w2_amount").value = "";
          document.getElementById("w2_amount").focused = "off";
          refreshUser();
          getRecentData();
          setErr("");
        },
        (err) => {
          setErr("");
          if (
            err.response.data.message.amount &&
            err.response.data.message.amount
          ) {
            setWalletTransferErrMsg(err.response.data.message.amount);
          } else {
            setWalletTransferErrMsg("");
            apiErrorToast(err);
            refreshUser();
            getRecentData();
          }
        }
      );
    } else {
      setErr("");
      const error = {
        message: "Kindly wait some time before another request",
      };
      setErr(error);
    }
  };

  useEffect(() => {
    getRecentData();
  }, []);

  const handleOpen = () => {
    // const timer = setTimeout(() => {
    if (authCtx?.isLoggedIn) refreshUser();
    // }, 30e98ba0);
    // return () => clearTimeout(timer);
  };

  // ############################################
  // TRANSFER CARDS COMPONENT HANDLING FUNCTIONS
  // ############################################
  const handleWalletTransfer = () => {
    if (showWalletTransfer && showWalletTransfer) {
      setShowWalletTransfer(!showWalletTransfer);
    }
  };
  const handleBankTransfer = () => {
    if (showBankTransfer && showBankTransfer) {
      setShowBankTransfer(!showBankTransfer);
    }
  };
  const [isMainWallet, setIsMainWallet] = useState(false);

  const handleWalletToggle = () => {
    setIsMainWallet(!isMainWallet);
  };
  const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

  const borderPulse = keyframes`
  0% { border-color: red; }
  50% { border-color: darkred; }
  100% { border-color: red; }
`;

  // const getParent = useCallback(() => {
  //   get(
  //     ApiEndpoints.GET_PARENT,
  //     "",
  //     () => {},
  //     (res) => {
  //       setResponse(res?.data?.data);
  //     },
  //     (err) => {
  //       apiErrorToast(err);
  //     }
  //   );
  // }, []);
  // useEffect(() => {
  //   if (user.role === "Dd" || user.role === "Ad" || user.role === "Md") {
  //     getParent();
  //   }
  //   return () => {};
  // }, [getParent, user]);
  return (
    <>
      {user.aggreement === 1 ? (
        <Grid
          sx={{
            pb: 0.5,
            pt: 0.5,
            ml: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#E1EBEC", // Light background for the card
              border: "1px solid #C8D8D6", // Subtle border for each card
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "2px",
              overflow: "hidden",
              borderRadius: "5px",
            }}
          >
            <>
              {/* {(user.role == "Ret" ||
                user.role == "Ad" ||
                user.role == "Dd" ||
                user.role == "Md") && (
                <Grid
                  item
                  xs="auto"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      padding: "8px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: { lg: "160px", sm: "150px", xs: "150px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginLeft: "8px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#212b5a",
                          fontSize: "10px",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Phone
                          sx={{ color: "#0e98ba", fontSize: "14px", mr: 0.5 }}
                        />
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            marginLeft: "4px",
                          }}
                        >
                          {getFirmContact()}
                        </span>
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          color: "#212b5a",
                          fontSize: "10px",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          mt: "0.5",
                        }}
                      >
                        <Email
                          sx={{ color: "#0e98ba", fontSize: "14px", mr: 0.5 }}
                        />
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            marginLeft: "4px",
                          }}
                        >
                          {getFirmEmail()}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderLeft: "2px solid #1FA2FF", // Change to a prominent color, e.g., orange
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for emphasis
                      mt: 1,
                      mb: 1,
                      height: "auto", // Ensure the divider stretches the full height
                      borderRadius: "4px", // Optional: add rounded corners to the divider
                    }}
                  />
                  {/* Divider */}
              {/* <Divider textAlign="right" sx={{ borderBottom: "2px solid black", mt: 1, mb: 1 }} /> */}
              {/* </Grid> */}
              {/* )} */}
              {/* <FormatBoldIcon /> */}
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  borderLeft: "2px solid #1FA2FF", // Change to a prominent color, e.g., orange
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for emphasis
                  mt: 1,
                  mb: 1,
                  height: "auto", // Ensure the divider stretches the full height
                  borderRadius: "4px", // Optional: add rounded corners to the divider
                }}
              />
              {/* <FormatItalicIcon /> */}
              {/* ASM Card */}
              {/* {(user.role === "Dd" ||
                user.role === "Ret" ||
                user.role === "Ad" ||
                user.role === "Md") && (
                <>
                  <Grid
                    item
                    xs="auto"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                        width: { lg: "140px", sm: "125px" },
                      }}
                    >
                      <Box sx={{ minWidth: "100%", textAlign: "left" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#b71c1c",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          ASM
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#212b5a", fontSize: "10px" }}
                        >
                          Mobile No-{parentResponse?.asm}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderLeft: "2px solid #1FA2FF", // Change to a prominent color, e.g., orange
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for emphasis
                        mt: 1,
                        mb: 1,
                        height: "auto", // Ensure the divider stretches the full height
                        borderRadius: "4px", // Optional: add rounded corners to the divider
                      }}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderLeft: "2px solid #1FA2FF", // Change to a prominent color, e.g., orange
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for emphasis
                      mt: 1,
                      mb: 1,
                      height: "auto", // Ensure the divider stretches the full height
                      borderRadius: "4px", // Optional: add rounded corners to the divider
                    }}
                  />
                </>
              )} */}

              {/* DD Card */}
              {user.role !== "Dd" &&
                user.role !== "Ret" &&
                user.role !== "Acc" &&
                user.role !== "Ad" &&
                user.role !== "Admin" &&
                user.role !== "Asm" &&
                user.role !== "Zsm" && (
                  <>
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          padding: "5px",
                          // backgroundImage: gradientColor,
                          // borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          width: { lg: "140px", sm: "130px" },
                          // border: "2px solid rgb(200, 216, 214)",
                        }}
                      >
                        <Box sx={{ minWidth: "100%", textAlign: "left" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              variant="body"
                              sx={{
                                color: "Black",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              DD-{" "}
                              <span style={{ fontSize: "12px" }}>
                                {user.name}
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              color: "#212b5a",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            Mobile No-{" "}
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              {user.username}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderLeft: "2px solid #1FA2FF",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        mt: 1,
                        mb: 1,
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  </>
                )}

              {user.role !== "Admin" &&
                user.role !== "Asm" &&
                user.role !== "Acc" &&
                user.role !== "Zsm" && (
                  <>
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          width: { lg: "145px", sm: "140px", xs: "90px" },
                          overflow: "hidden",
                        }}
                      >
                        <AccountBalanceWalletIcon
                          sx={{ fontSize: 15, color: "#0e98ba", mr: 1 }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#b71c1c",
                              fontSize: "12px",
                              marginRight: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Wallet Balance
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#212b5a",
                              fontSize: "12px",
                              marginRight: 1,
                              fontWeight: "bold",
                            }}
                          >
                            ₹ {numberSetter(user.w1 / 100)}
                          </Typography>
                        </Box>

                        <RefreshComponent
                          refresh={userRequest}
                          onClick={() => refreshUser()}
                          sx={{ mb: 2, color: "#0e98ba", fontSize: 15, ml: 1 }}
                        />
                      </Box>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderLeft: "2px solid #1FA2FF",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        mt: 1,
                        mb: 1,
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  </>
                )}

              {(user.role == "Ret" ||
                user.role == "Ad" ||
                user.role == "Dd" ||
                user.role == "Md") &&
                user.hold > 0 && (
                  <>
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          width: { lg: "140px", sm: "130px", xs: "90px" },
                          overflow: "hidden",
                        }}
                      >
                        <AccountBalanceWalletIcon
                          sx={{ fontSize: 15, color: "#0e98ba", mr: 1 }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#b71c1c",
                              fontSize: "12px",
                              marginRight: 1,
                            }}
                          >
                            Hold Money
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#212b5a",
                              fontSize: "12px",
                              marginRight: 1,
                            }}
                          >
                            ₹ {numberSetter(user.hold / 100)}
                          </Typography>
                        </Box>

                        <RefreshComponent
                          refresh={userRequest}
                          onClick={() => refreshUser()}
                          sx={{ mb: 2, color: "#0e98ba", fontSize: 15, ml: 1 }}
                        />
                      </Box>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderLeft: "2px solid #1FA2FF",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        mt: 1,
                        mb: 1,
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  </>
                )}

              {/* Aeps  */}
              {user.role !== "Ad" &&
                user.role !== "Md" &&
                user.role !== "Acc" &&
                user.role !== "Admin" &&
                user.role !== "Asm" &&
                user.role !== "Zsm" && (
                  <>
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          padding: "5px",

                          display: "flex",
                          alignItems: "center",
                          width: { lg: "155px", sm: "155px", xs: "130px" },

                          overflow: "hidden",
                        }}
                      >
                        <AccountBalanceWalletIcon
                          sx={{ fontSize: 15, color: "#0e98ba", mr: 1 }}
                        />
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#b71c1c",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            AEPS Balance
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#212b5a",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            ₹ {numberSetter(user.w2 / 100)}
                          </Typography>
                        </Box>
                        <RefreshComponent
                          refresh={userRequest}
                          onClick={() => refreshUser()}
                          sx={{ mb: 2, color: "#0e98ba", fontSize: 15, ml: 2 }}
                        />
                      </Box>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderLeft: "2px solid #1FA2FF",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        mt: 1,
                        mb: 1,
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  </>
                )}
              {/* {(user.role === "Ad" || user.role === "Md") && (
                <>
                  <Grid item xs="auto">
                    <Box
                      sx={{
                        padding: "5px",

                        display: "flex",
                        alignItems: "center",
                        width: { lg: "155px", sm: "140px", xs: "110px" },

                        overflow: "hidden",
                      }}
                    >
                      <AccountBalanceWalletIcon
                        sx={{ fontSize: 15, color: "#0e98ba", mr: 1 }}
                      />
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#b71c1c", fontSize: "10px" }}
                        >
                          Comm Wallet
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#212b5a", fontSize: "10px" }}
                        >
                          ₹ {numberSetter(user.w3 / 100)}
                        </Typography>
                      </Box>
                      <RefreshComponent
                        refresh={userRequest}
                        onClick={() => refreshUser()}
                        sx={{ mb: 2, color: "#0e98ba", fontSize: 15, ml: 1 }}
                      />
                    </Box>
                  </Grid>
                </>
              )} */}
            </>
          </Box>
        </Grid>
      ) : null}
    </>
  );
};

export default WalletCard;
